/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

/* Plugins */
import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Image from 'gatsby-image';
import { useQuerySubscription } from 'react-datocms';

/* Components */
import {
  SEO, Container, Section, Article, PostView, Heading,
} from '../../components/components';

const Podglad = () => {
  const { status, error, data } = useQuerySubscription({
    query: `
      query AppQuery {
        allArticles(first: 1) {
          title
          author
          teaser
          _seoMetaTags {
            attributes
          }
          featureImage {
            responsiveImage(imgixParams: {w: 1160}) {
              srcSet
              src
              alt
              title
            }
          }
          articleContent {
            ... on HeadingRecord {
              headingContent
              id
            }
            ... on ParagraphRecord {
              paragraphContent
              id
            }
            ... on ArticleImageRecord {
              imageData {
                responsiveImage {
                  srcSet
                  src
                  alt
                  title
                }
              }
              id
            }
          }
        }
      }`,
    token: 'bc29a2f378a40566f5a501c0984298',
  });

  const {
    title, author, teaser, featureImage, articleContent, _seoMetaTags,
  } = { ...data?.allArticles[0] };
  const { ...metaTags } = { ..._seoMetaTags };

  const SEO_TITLE = metaTags[1]?.attributes.content;
  const SEO_DESCRIPTION = metaTags[3]?.attributes.name === 'description' ? metaTags[3]?.attributes.content : teaser;

  return (
    <>
      <SEO title={SEO_TITLE} description={SEO_DESCRIPTION} noIndex />
      <Container subPage>
        <Heading headingLevel="h1" title="PODGLĄD ARTYKUŁU" centered />
        <Section article>
          <Article>
            <PostView
              preview
              title={title}
              featureImage={featureImage?.responsiveImage}
              author={author}
              children={articleContent?.map((item) => {
                const itemKey = Object.keys(item)[0];
                switch (itemKey) {
                  case 'headingContent':
                    return <Markdown key={item.id} rehypePlugins={[rehypeRaw]} children={item[itemKey]} />;
                  case 'paragraphContent':
                    return <Markdown key={item.id} rehypePlugins={[rehypeRaw]} children={item[itemKey]} />;
                  case 'imageData':
                    return <Image key={item.id} fixed={item[itemKey].responsiveImage} />;
                  default:
                    return null;
                }
              })}
            />
          </Article>
        </Section>
      </Container>

    </>

  );
};

export default Podglad;
